import axios from 'axios';
import { getAuthToken, removeAuthToken } from './Utils';

const API_URL = process.env.REACT_APP_API_URL;
const VERIFY_TOKEN_ENDPOINT = `${API_URL}/auth/verify-token`;

export const verifyJWTApiCall = async () => {
  const token = getAuthToken();
  if (!token) {
    throw new Error('No auth token found');
  }

  try {
    const response = await axios.post(VERIFY_TOKEN_ENDPOINT, {}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });
    if (response.data.valid && response.data.user) {
      return response.data.user;
    } else {
      throw new Error('Invalid response from server');
    }
  } catch (error) {
    console.error('JWT verification error:', error);
    removeAuthToken();
    throw new Error(error.response?.data?.message || 'Invalid or expired token');
  }
};