import React, { lazy, Suspense, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './index.scss';
import reportWebVitals from './reportWebVitals';
import { UserDataProvider, useUserData } from './Context/UserData';
import LoadingScreen from './Components/LoadingScreen/LoadingScreen';
import { verifyJWTApiCall } from './Api/Auth/VerifyToken';

const App = lazy(() => import('./App'));
const Login = lazy(() => import('./Pages/Login/Login'));
const Register = lazy(() => import('./Pages/Register/Register'));

if (process.env.REACT_APP_ENV !== 'live') {
  console.group('REACT_APP_DEBUG - ENV VARS')
  console.info('REACT_APP_DEBUG:' + process.env.REACT_APP_DEBUG)
  console.info('REACT_APP_ENV: ' + process.env.REACT_APP_ENV)
  console.info('API ENDPOINT: ' + process.env.REACT_APP_API_URL)
  console.groupEnd()
}

const ProtectedRoute = ({ children }) => {
  const { state, loginSuccessAction, loginFailureAction } = useUserData();
  
  useEffect(() => {
    const verifyAuth = async () => {
      if (!state.user && state.showLoadingState) {
        try {
          const user = await verifyJWTApiCall();
          loginSuccessAction({ user });
        } catch (error) {
          loginFailureAction();
        }
      }
    };

    verifyAuth();
  }, [loginSuccessAction, loginFailureAction, state.user, state.showLoadingState]);

  if (state.showLoadingState) {
    return <LoadingScreen />;
  }

  return state.user ? children : <Navigate to="/login" />;
};

const AppRoutes = () => (
  <Router>
    <Suspense fallback={<LoadingScreen />}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route
          path="/*"
          element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Suspense>
  </Router>
);

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <UserDataProvider>
      <AppRoutes />
    </UserDataProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
