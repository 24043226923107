import React, { createContext, useContext, useReducer, useCallback } from 'react';
import { logout } from '../Api/Auth/Utils';
import { verifyJWTApiCall } from '../Api/Auth/VerifyToken';

const initialState = {
  user: null,
  showLoadingState: true,
};

const ActionTypes = {
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  UPDATE_PROFILE: 'UPDATE_PROFILE',
  LOGOUT: 'LOGOUT',
  SET_LOADING: 'SET_LOADING',
};

const userDataReducer = (state, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        showLoadingState: false,
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        showLoadingState: false,
      };
    case ActionTypes.LOGOUT:
      return {
        ...state,
        user: null,
        showLoadingState: false,
      };
    case ActionTypes.UPDATE_PROFILE:
      return {
        ...state,
        user: { ...state.user, ...action.payload },
      };
    case ActionTypes.SET_LOADING:
      return {
        ...state,
        showLoadingState: action.payload,
      };
    default:
      return state;
  }
};

const UserDataContext = createContext();

export const useUserData = () => {
  const context = useContext(UserDataContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};

export const UserDataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(userDataReducer, initialState);

  const loginSuccessAction = useCallback((userData) => {
    dispatch({ type: ActionTypes.LOGIN_SUCCESS, payload: userData });
  }, []);

  const loginFailureAction = useCallback(() => {
    dispatch({ type: ActionTypes.LOGIN_FAILURE });
  }, []);

  const updateProfileAction = useCallback((profileData) => {
    dispatch({ type: ActionTypes.UPDATE_PROFILE, payload: profileData });
    // Here you would typically make an API call to update the user's profile
    // and then dispatch the action with the response data
  }, []);

  const logoutAction = useCallback(() => {
    logout();
    dispatch({ type: ActionTypes.LOGOUT });
  }, []);

  const setLoading = useCallback((isLoading) => 
    dispatch({ type: ActionTypes.SET_LOADING, payload: isLoading }), []);

  const verifyAuthAction = useCallback(async () => {
    if (state.showLoadingState) {
      try {
        const user = await verifyJWTApiCall();
        loginSuccessAction({ user });
      } catch (error) {
        console.error('Verification failed:', error);
        logoutAction();
      }
    }
  }, [state.showLoadingState, loginSuccessAction, logoutAction]);

  const value = {
    state,
    loginSuccessAction,
    loginFailureAction,
    updateProfileAction,
    logoutAction,
    verifyAuthAction,
    setLoading
  };

  return (
    <UserDataContext.Provider value={value}>
      {children}
    </UserDataContext.Provider>
  );
};