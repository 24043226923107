import Cookies from 'js-cookie';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const TOKEN_COOKIE_NAME = 'authToken';
const TOKEN_EXPIRY_DAYS = 7;

export const setAuthToken = (token) => {
  Cookies.set(TOKEN_COOKIE_NAME, token, {
    expires: TOKEN_EXPIRY_DAYS,
    secure: process.env.NODE_ENV === 'production',
    sameSite: 'Strict',
  });
};

export const getAuthToken = () => Cookies.get(TOKEN_COOKIE_NAME);

export const removeAuthToken = () => Cookies.remove(TOKEN_COOKIE_NAME);

export const logout = () => {
  removeAuthToken();
};

export const authdAPICall = () => {
  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  instance.interceptors.request.use((config) => {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  return instance;
};